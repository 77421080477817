@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  text-decoration: none;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 10px;
  }
}

body,
body * {
  font-family: 'Inter', 'sans-serif' !important;
}
